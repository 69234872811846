import React from 'react'
import { BiCheckCircle } from 'react-icons/bi'
import { checklistItems } from '../../../constants';
import groupPng from '../../../constants/group.png'

const Workflow = () => {
    return (
      <div id="workflow" className="mt-20 font-inter">
 <h2 className="text-3xl sm:text-5xl lg:text-6xl text-center mt-6 tracking-wide font-bold">
        Jak powinna wyglądać Twoja praca z {" "}
        <span className="bg-gradient-to-r from-secondarylight to-white text-transparent bg-clip-text">
          naszą aplikacją?
        </span>
      </h2>
        <div className="flex flex-wrap justify-center mt-20">
          <div className="p-2 w-full lg:w-1/2">
            <img src={groupPng} alt="Functions" />
          </div>
          <div className="pt-12 w-full lg:w-1/2">
            {checklistItems.map((item, index) => (
              <div key={index} className="flex mb-12">
                <div className="text-green-400 mx-6 bg-neutral-900 h-10 w-10 p-2 justify-center items-center rounded-full">
                  <BiCheckCircle className='w-6 h-6' />
                </div>
                <div>
                  <h5 className="mt-1 mb-2 text-xl">{item.title}</h5>
                  <p className="text-md text-neutral-500">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

export default Workflow
